.drawer {
  padding-top: 70px;
  width: 175px;
  align-items: flex-start;
  height: 100vh !important;
  overflow-y: auto !important;
  background-color: var(--color-dark) !important;
}

.bottom_items {
  margin-top: 15vh !important;
  margin-bottom: 100px !important;
  height: inherit;
}

.link {
  text-decoration: none;
  color: white;
}
.icon {
  left: 0;
  min-width: 40px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #838ea4 !important;
}
.left_line {
  border-left: 4px solid var(--secondary-color);
}
.collapsed_item_link {
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgb(250, 248, 248);
}
.collapse_icon {
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.collapsed_item_text > span {
  font-size: 0.9rem;
}

.area {
  height: calc(100vh - 65px);
  padding-left: 175px;
}

.dashboard_logo {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo_classfy {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@media (max-width: 800px) {
  .drawer {
    width: 80px;
  }
  .area {
    padding-left: 80px;
  }
  .text {
    display: none;
  }
  .logo_classfy {
    width: 450px;
  }
  .collapsed_item_text {
    display: none;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
