.card {
  padding: 0 2rem;
  height: calc(100vh - 120px);
}

.content {
  width: 80%;
  max-width: 800px;
  margin: 50px auto;
}
