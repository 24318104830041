.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 15px;
}

.table {
  height: calc(100vh - 155px);
  max-height: calc(100vh - 155px);
}

.agora {
  display: flex;
  align-items: center;
  gap: 5px;
}
