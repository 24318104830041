.filter {
  margin: 10px 40px;
  display: flex;
  align-items: flex-start;
}
.filter > div {
  margin-left: 10px;
  margin-right: 10px;
}

.gridDayInfo {
  font-size: 18px;
  font-weight: bold;
  margin: 1rem 40px;
}
.gridPhoto {
  margin: 20px 30px;
  cursor: pointer;
}

.largePhotoModal {
  max-width: 100%;
}
