.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 15px;
}

.table {
  height: calc(100vh - 155px);
  max-height: calc(100vh - 155px);
}

.tag {
  padding: 7px 16px;
  border-radius: 16px;
  width: fit-content;
  margin: 0 auto;
  font-size: 0.8125rem;
  cursor: default;
}
.teacher {
  background: var(--primary-color);
  color: white;
}
.trainee {
  background: var(--secondary-color);
}
.trial {
  background: var(--dadada);
}
.student {
  background: var(--purpletronic);
  color: white;
}
