.header {
  height: 65px;
  display: flex;
  justify-content: center;
  z-index: 1300 !important;
  background: var(--color-dark) !important;
}

.logo {
  max-height: 40px;
}

.toolbar {
  justify-content: space-between;
  align-items: center;
}

.actions {
  width: 120px;
  display: flex;
  justify-content: space-between;
}

.link {
  color: white;
  line-height: 0;
}

@media (max-width: 800px) {
  .logo {
    max-height: 30px;
  }
}

@keyframes highlightAnimation {
  0% {
    color: black;
  }
  50% {
    color: grey;
  }
  100% {
    color: white;
  }
}

.highlight {
  font-size: 24px;
  padding: 10px;
  display: inline-block;
  animation: highlightAnimation 3s infinite;
}
