.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 15px;
}
.actions {
  display: flex;
  align-items: center;
  gap: 5px;
}
.download {
  color: var(--primary-color);
  cursor: pointer;
}

.table {
  height: calc(100vh - 155px);
  max-height: calc(100vh - 155px);
}
.agoraCell {
  max-width: 15px !important;
  width: 15px !important;
}
.phoneCell {
  max-width: 80px !important;
  width: 80px !important;
}

.permissions {
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}
