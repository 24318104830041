.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 15px;
}

.table {
  max-height: calc(100vh - 150px);
}
