body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}

* {
  --primary-color: #8cc63f;
  --secondary-color: #f89f41;
  --secondary-color-30: rgba(248, 160, 65, 0.3);
  --error-color: #f0513c;
  --warning-color: #ef5938;
  --warning-color-30: rgba(239, 89, 56, 0.3);
  --warning-color-10: rgba(239, 89, 56, 0.1);
  --info-color: #17ab7d;
  --success-color: #18ab50;
  --purpletronic: #8080f8;
  --purpletronic-20: #8080f833;
  --seadapted: #34e9aa;
  --sandground: #fa9f89;
  --color-dark: #22262e;
  --dadada: #dadada;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
