.main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f89f41;
}

.card {
  max-width: calc(100% - 40px);
  padding: 40px;
}

.card > .actions {
  justify-content: center;
  margin-bottom: 8px;
  padding: 8px 0 0;
}

.image {
  max-height: 70px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  padding-bottom: 20px;
  padding-left: 25px;
}

.googleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
