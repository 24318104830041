.filter {
  margin: 10px 40px;
  display: flex;
  align-items: flex-start;
}

.filter > div {
  margin-left: 10px;
  margin-right: 10px;
}
